import { useState, useEffect } from 'react';
import { useUser } from '../components/UserContext'; // dopasuj ścieżkę

export const useQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useUser();

  const isTestAccessValid = user && user.test_access_till
    ? new Date() < new Date(user.test_access_till)
    : false;

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

        const payload = {
          demo: !isTestAccessValid
        };

        const headers = {
          'Content-Type': 'application/json',
        };

        const token = localStorage.getItem('access_token');
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch(`${API_BASE_URL}/strzelasz/get_random_questions`, {
          method: 'POST',
          headers,
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Błąd pobierania pytań');
        }

        const data = await response.json();
        setQuestions(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchQuestions();
  }, [isTestAccessValid]);

  return { questions, error };
};
