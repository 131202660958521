// src/components/QuestionDetail.jsx
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import styles from '../css/modules/Article.module.css';

const Article = () => {
  // Używamy hooka useState do przechowywania danych pytania
  const [questionData, setQuestionData] = useState(null);
  // Ustalony slug dla konkretnego pytania
  const slug = "czym-nie-jest-bron-w-rozumieniu-ustawy";

  // Pobieramy dane z API po wyrenderowaniu komponentu
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/strzelasz/get_question_by_slug`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ slug }),
        });
        const data = await response.json();
        setQuestionData(data);
      } catch (error) {
        console.error('Błąd podczas pobierania danych: ', error);
      }
    };

    fetchData();
  }, [slug]);

  // Dopóki dane nie zostaną pobrane, wyświetlamy komunikat "Loading..."
  if (!questionData) {
    return <div>Ładowanie...</div>;
  }

  // Destrukturyzacja pobranych danych
  const {
    title,
    description,
    h1,
    alt,
    question,
    answerA,
    answerB,
    answerC,
    correctAnswer,
    legal_basis,
    legal_details,
    points,
    explanation,
    action,
  } = questionData;

  return (
    <div className={styles.container}>
      {/* Używamy React Helmet dla SEO */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <h1 className={styles.header}>{h1}</h1>
      {/* Przykładowy obraz – w zależności od struktury projektu można pobierać dynamicznie lub ustawić statyczną ścieżkę */}
      <img src="/path/to/illustration.webp" alt={alt} className={styles.image} />

      <section className={styles.questionSection}>
        <h2>Pytanie</h2>
        <p>{question}</p>
      </section>

      <section className={styles.answersSection}>
        <h3>Odpowiedzi</h3>
        <ul>
          <li className={correctAnswer === "answerA" ? styles.correct : ''}>
            <strong>A.</strong> {answerA}
          </li>
          <li className={correctAnswer === "answerB" ? styles.correct : ''}>
            <strong>B.</strong> {answerB}
          </li>
          <li className={correctAnswer === "answerC" ? styles.correct : ''}>
            <strong>C.</strong> {answerC}
          </li>
        </ul>
      </section>

      <section className={styles.legalSection}>
        <p>
          <strong>Podstawa prawna:</strong> {legal_basis} {legal_details}
        </p>
      </section>

      <section className={styles.explanationSection}>
        <h3>Wyjaśnienie</h3>
        <ReactMarkdown>{explanation}</ReactMarkdown>
      </section>

      <section className={styles.actionSection}>
      <ReactMarkdown>{action}</ReactMarkdown>
      </section>
    </div>
  );
};

export default Article;
