// src/App.js
import React from 'react';
import './css/modules/variables.css';
import { Routes, Route } from 'react-router-dom';
import StartScreen from './components/StartScreen';
import IntroEnemy from './components/IntroEnemy';
import QuizCyberpunk from './components/QuizCyberpunkSingle';
import Shop from './components/Shop';
import DefeatScreen from './components/DefeatScreen';
import CongratsScreen from './components/CongratsScreen';
import Layout from './components/Layout';
import TieScreen from './components/TieScreen';
import Rating from './components/Rating';
import Login from './components/Login';
import Regulamin from './components/Regulamin'
import PrivacyPolicy from './components/PrivacyPolicy'
import ContactForm from './components/ContactForm';
import ThankYou from './components/ThankYou';
import WhyCyberDriver from './components/WhyToBuyUs';
import PricingOptions from './components/PricingOptions';
import PurchaseRegistration from './components/PurchaseRegistration';
import { CartProvider } from './components/CartContext';
import GoogleTag from './components/GoogleTag';
import ExamQuestions1 from './components/ExamQuestions1';
import ExamQuestions2 from './components/ExamQuestions2';
import ExamQuestions3 from './components/ExamQuestions3';
import ExamQuestions4 from './components/ExamQuestions4';
import ExamQuestions5 from './components/ExamQuestions5';
import ExamQuestions6 from './components/ExamQuestions6';
import ExamQuestions7 from './components/ExamQuestions7';
import ExamQuestions8 from './components/ExamQuestions8';
import ExamQuestions9 from './components/ExamQuestions9';
import Article from './components/Article';

function App() {
  return (
    <CartProvider>
    <GoogleTag />
    <Layout>
      <Routes>
        <Route path="/" element={<WhyCyberDriver />} />
        <Route path="/start" element={<StartScreen />} />
        <Route path="/intro" element={<IntroEnemy />} />
        <Route path="/regulamin" element={<Regulamin />} />
        <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/artykul-egzamin-bron" element={<Article />} />
        <Route path="/defeated" element={<DefeatScreen />} />
        <Route path="/ranking" element={<Rating />} />
        <Route path="/dlaczego-nauka-na-patent-strzelecki-z-cyberdriver" element={<WhyCyberDriver />} />
        <Route path="/cennik" element={<PricingOptions />} />
        <Route path="/login" element={<Login />} />
        <Route path="/kontakt" element={<ContactForm />} />
        <Route path="/zakup" element={<PurchaseRegistration />} />
        <Route path="/podziekowanie" element={<ThankYou />} />
        <Route path="/pytania-na-pozwolenie-na-bron-patent-strzelecki" element={<ExamQuestions1 />} />
        <Route path="/pytania-egzaminacyjne-na-patent-strzelecki" element={<ExamQuestions2 />} />
        <Route path="/patent-strzelecki-odpowiedzi-na-pytania" element={<ExamQuestions2 />} />
        <Route path="/odpowiedzi-na-pytania-egzamin-na-bron-strzelecki" element={<ExamQuestions3 />} />
        <Route path="/patent-strzelecki-pytania-i-odpowiedzi-egzamin-na-bron" element={<ExamQuestions4 />} />
        <Route path="/egzamin-na-bron-odpowiedzi-do-najczestrzych-pytan" element={<ExamQuestions5 />} />
        <Route path="/testy-patent-strzelecki" element={<ExamQuestions6 />} />
        <Route path="/pytania-egzaminacyjne-testy-patent-strzelecki" element={<ExamQuestions7 />} />
        <Route path="/egzamin-pozwolenie-na-bron-testy-patent-strzelecki" element={<ExamQuestions8 />} />
        <Route path="/testy-patent-strzelecki-pytania-i-opowiedzi-z-pzss" element={<ExamQuestions9 />} />
        <Route path="/:enemySlug/success" element={<CongratsScreen />} />
        <Route path="/quiz/:enemySlug" element={<QuizCyberpunk />} />
        <Route path="/:enemySlug/tie" element={<TieScreen />} />
      </Routes>
    </Layout>
    </CartProvider>
  );
}

export default App;